import axios from 'axios';
import { useEffect, useState } from 'react';
import { Placeholder } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import { LightningChargeFill, Fan } from 'react-bootstrap-icons';
function DataPane() {
    const [currentWattage, setCurrentWattage] = useState();
    const [ventilation, setVentilation] : [Ventilation | undefined, (ventilation: Ventilation | undefined) => void]  = useState<Ventilation >();

    useEffect(() => {
        setInterval(fetch_data(), 1000)
    }, [])
    return (
            <ListGroup>
                <ListGroup.Item>
                    <LightningChargeFill size={24} className="text-info m-1" />
                    {(() => {
                        if (currentWattage) {
                            return (<>
                                {' ' + currentWattage + 'W'}
                            </>
                            )
                        } else {
                            return (
                                <Placeholder animation="glow">
                                    <Placeholder xs={1} />
                                </Placeholder>);
                        }
                    })()}
                </ListGroup.Item>
                <ListGroup.Item>
                    <Fan size={24} className="text-info m-1" />
                    {(() => {
                        if (ventilation) {
                            return (<>

                                {' ' + ventilation.mode}
                            </>
                            )
                        } else {
                            return (
                                <Placeholder animation="glow">
                                    <Placeholder xs={1} />
                                </Placeholder>);
                        }
                    })()}</ListGroup.Item>
            </ListGroup>
    );

    function fetch_data(): () => void {
        return () => {
            axios.get('/api/current_wattage').then(
                response => {
                    setCurrentWattage(response.data);
                }
            ).catch(error => {
                //console.error(error);
                setCurrentWattage(undefined);
            });
            axios.get<Ventilation>('/api/ventilation').then( 
                response => {
                    setVentilation(response.data);
                }
            ).catch(error => {
                setVentilation(undefined);
                //console.error(error);
            });
        }
    }
}

export default DataPane;