import axios, { AxiosError } from "axios";
import { ComponentType, useEffect, useState } from "react";
import { Placeholder, Spinner } from "react-bootstrap";
import { IconProps } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";

type ControlButtonProps = {
    Icon: ComponentType<IconProps>,
    name: string,
    description: string
}

type ControlButtonState = {
    loading: boolean,
    active: boolean,
    enabled: boolean,
    error: string
}

enum ActionState { Inactive = "Inactive", On = "On", Off = "Off" }
interface Action {
    name: string,
    state: ActionState,
}

function ControlButton(props: ControlButtonProps) {

    const [buttonState, setButtonState] = useState<ControlButtonState>({
        loading: false,
        active: false,
        enabled: false,
        error: "",
    });

    

    function fetch_data() {
        return () => {
            axios.get<Action>('/api/actions/' + props.name).then(
                response => {
                    switch (response.data.state) {
                        case ActionState.On:
                            setButtonState({ ...buttonState, active: true, enabled: true, error: "" });
                            break;
                        case ActionState.Off:
                            setButtonState({ ...buttonState, active: false, enabled: true, error: "" });
                            break
                        case ActionState.Inactive:
                            setButtonState({ ...buttonState, enabled: false, error: "" });
                            break;
                    }
                }
            ).catch((err: Error | AxiosError) => {
                if (axios.isAxiosError(err)) {
                    if (err.response?.status === 404 || err.response?.status === 500) {
                        setButtonState({ ...buttonState, enabled: false, error: "unreachable" });
                    }
                } else {
                    console.log(err);
                }
            });
        }
    }

    useEffect(() => {
        fetch_data();
        setInterval(fetch_data(), 1000);
    }, [])

    function action(): () => void {
        return () => {
            setButtonState({ ...buttonState, loading: true });
            let action: Action = { name: props.name, state: buttonState.active ? ActionState.Off : ActionState.On };
            let token = localStorage.getItem('token');
            axios.post('/api/actions/' + props.name, action, {
                headers: {
                  'Authorization': `Bearer ${token}` 
                }
              })
                .then(_ => {
                    setTimeout(() => {
                        setButtonState({ ...buttonState, loading: false, active: !buttonState.active });
                    }, 1000);
                })
                .catch(error => {
                    console.error(error);
                    setButtonState({ ...buttonState, loading: false });
                    if (buttonState.error === "") {
                        setButtonState({ ...buttonState, error: error });
                        setTimeout(() => {
                            setButtonState({ ...buttonState, error: "" });
                        }, 10000);
                    }
                });
        }
    }

    
    let icon;
    let helpText;
    if (buttonState.loading) {
        icon = <Spinner />
    } else {
        icon = <props.Icon size={32} className={'d-flex align-items-center' + (buttonState.error !== "" ? "text-danger" : "")} />
    }
    if (buttonState.error === "") {
        helpText = buttonState.active ? 'On' : 'Off'
    } else {
        helpText = (<Placeholder animation="glow">
        <Placeholder xs={3} />
    </Placeholder>);
    }
    return (
        <>
            <style type="text/css">
                {`
                .button-icon {
                    display: inline-block;
                    text-align: left;
                    height: 34px;
                }

                .button-text {
                    display: inline-block;
                    text-align: left
                }

                `}
            </style>
            <div className="d-grid p-1">
                <Button className="text-start rounded-3 p-0 m-0" onClick={action()} disabled={(!buttonState.enabled)} active={(buttonState.active)}>
                    <div className="d-flex align-middle">
                        <div className="button-icon m-2">{icon}</div>
                        <div className="ms-1 fs-6 button-text text-truncate">
                            {props.description}<br />
                            {helpText}
                        </div>
                    </div>
                </Button>
            </div>
        </>
    );

}

export default ControlButton;