import { Navbar, Container, NavDropdown } from "react-bootstrap"
import { BoxArrowRight, PersonCircle } from "react-bootstrap-icons";


function NavBarImpl() {
  const dropDownIcon = (<PersonCircle size={32} className="me-1"/>)
  return (
    <Navbar expand="lg" className="navbar-dark bg-primary">
      <Container fluid>
        <Navbar.Brand className="primary ms-3" href="#home">Vallgräsvägen 3</Navbar.Brand>
        <NavDropdown title={dropDownIcon} align="end" id="basic-nav-dropdown" className="dropdown-menu-left me-2">
              <NavDropdown.Item href="/logout">
                <BoxArrowRight/> Log out
              </NavDropdown.Item>
            </NavDropdown>
      </Container>
    </Navbar>
  );
}
export default NavBarImpl;