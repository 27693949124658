import axios, { AxiosError } from "axios";
import React, { ChangeEvent, FormEvent, useState } from "react"
import { Alert, Button, Col, Container, Form, FormGroup, FormLabel, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom";

interface LoginRequest {
    username: string,
    password: string,
}

interface LoginResponse {
    token: string
}
export const LoginPage = () => {
    const [state, setState] = useState({
        username: "",
        password: "",
        error: false
    });
    const navigate = useNavigate();

    const onFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value: typeof state[keyof typeof state] = event.target.value;
        setState({ ...state, [event.target.id]: value });
    };

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const request: LoginRequest = { username: state.username, password: state.password };
        axios.post<LoginResponse>('/api/login', request).then((response) => {
            setState({ ...state, error: false });
            console.log(response);
            localStorage.clear();
            localStorage.setItem('token', response.data.token);
            setTimeout(() => {
                console.log("Navigating");
                navigate("/home", { replace: true });
            }, 500);
        })
            .catch((err: Error | AxiosError) => {
                setState({ ...state, error: true });
                console.log(err);
            });
    };
    const toast = (<Alert variant="danger" className="mt-2">Wrong Username/Password</Alert>)
    return (
        <React.Fragment>
            <Container className="my-5">
                <h2 className="fw-normal mb-5">Login</h2>
                <Row>
                    <Col md={{ span: 6 }}>
                        {state.error ? toast : null}
                        <Form id="loginForm" onSubmit={onSubmit}>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'login-username'}>Username</FormLabel>
                                <input type={'text'} className="form-control" id={'username'} name="username" required onChange={onFieldChange} />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'login-password'}>Password</FormLabel>
                                <input type={'password'} className="form-control" id={'password'} name="password" required onChange={onFieldChange} />
                            </FormGroup>
                            <Button type="submit" className="btn-success mt-2" id="login-btn">Login</Button>

                        </Form>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}