import { Routes, Route, Navigate, Router, BrowserRouter } from "react-router-dom";
import AuthWrapper from "./auth/AuthWrapper";
import HomePage from "./pages/HomePage";
import { LoginPage } from "./pages/LoginPage";
import { Logout } from "./pages/Logout";


function App() {
  return (
    <BrowserRouter>
  <Routes>
    <Route element={<AuthWrapper />}>
      <Route path="/" element={<HomePage />} />
    </Route>
    <Route path="/login" element={<LoginPage />} />
    <Route path="/logout" element={<Logout />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
</BrowserRouter>
  );
}

export default App;
