import { Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Controls from '../components/Controls';
import DataPane from '../components/DataPane';
import NavBarImpl from '../components/Navbar';
function HomePage() {
  return (
    <>
      <NavBarImpl />
      <Container fluid>
        <Row xs="1" md="2" className='g-1 pt-2 mt-1'>
          <Col className="bg-secondary">
            <Controls />
          </Col>
          <Col className="bg-secondary">
            <DataPane />
          </Col>
        </Row>
      </Container>
      </>
  );
}

export default HomePage;