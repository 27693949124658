import { Col, Row } from "react-bootstrap";
import { EggFried, Fire, MoonStars, Water } from "react-bootstrap-icons";
import ControlButton from "./ControlButton";

function Controls() {
    return (
        <Row xl="3" xs="2" className="g-0">
            <Col>
            <ControlButton Icon={Fire} name="ventilation_controller" description="Light the fire" />
            </Col>
            <Col>
            <ControlButton Icon={MoonStars} name="night_controller" description="Good Night" />
            </Col>
            <Col>
            <ControlButton Icon={EggFried} name="dinner_table_controller" description="Dinner Table" />
            </Col>
            <Col>
            <ControlButton Icon={Water} name="pool_lights_controller" description="Pool Lightning" />
            </Col>
        </Row>
    )
}

export default Controls;